
import Vue from "vue";

import { authMapActions } from "@/store/modules/auth";
import Password from "@/components/User/Password.vue";
import { logoPath } from "@/helpers/generalHelpers";

export default Vue.extend({
  name: "claimAccount",
  components: {
    Password
  },
  created() {
    this.loading = true;
    localStorage.removeItem("access");
    localStorage.removeItem("claim_account");
    const hasToken = this.hasClaimAccountToken();
    if (hasToken) {
      const token = this.$route.query["token"] as string;
      localStorage.setItem("claim_account", token);
      this.canClaimAccount()
        .then((r: any) => {
          const { token, displayName, email } = r.data;
          this.displayName = displayName;
          this.userEmail = email;
          this.hasResetToken = true;
          localStorage.removeItem("access");
          localStorage.removeItem("claim_account");
          localStorage.setItem("password_reset", token);
          this.$router.replace({ query: {} }).catch(() => {});
        })
        .catch((e: any) => {
          this.error = e.message || "Token invalid";
          this.$bugSnagClient.notify(e);
          this.$router.replace({ query: {} }).catch(() => {});
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.error = "Token invalid";
      // this.$router.replace("/login").catch(() => {});
    }
  },
  data() {
    return {
      error: "",
      message: "",
      loading: false,
      credentials: {
        email: "",
        password: ""
      },
      hasResetToken: false,
      claimRequestSent: false,
      passwordUpdated: false,
      displayName: "",
      canSubmit: false,
      userEmail: ""
    };
  },
  methods: {
    ...authMapActions([
      "requestClaimAccount",
      "updatePassword",
      "canClaimAccount"
    ]),
    getLogoPath() {
      return logoPath();
    },
    async initRequestClaimAccount(): Promise<void> {
      this.loading = true;
      try {
        const { email } = this.credentials;
        const { message } = await this.requestClaimAccount({ email });
        this.message = message;
        this.claimRequestSent = true;
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    async updatePasswordAction() {
      try {
        this.loading = true;
        const { password } = this.credentials;
        await this.updatePassword({ password });
        this.passwordUpdated = true;
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    hasClaimAccountToken() {
      return this.$route.query["token"] && this.$route.query["token"] !== "";
    },
    goToLogin() {
      this.$router.push({
        path: "/login",
        query: { email: this.userEmail }
      });
    }
  }
});
